// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import moment from 'moment'

import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import sortedUniq from 'lodash/sortedUniq'
import reverse from 'lodash/reverse'

import classNames from 'classnames'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { StaticQuery, graphql } from 'gatsby'

import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../link'
import '../link/style.less'

import StandardPageWrapper from '../standard-page-wrapper'
import '../standard-page-wrapper/style.less'

import './style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query EventPageWrapperQuery {
    allEventsJson {
      edges {
        node {
          title
          routeSlug
          publishedTimestamp
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page Wrapper */
const PageWrapper = ({ children, className = null, ...rest }) => (
  <StaticQuery
    query={query}
    render={(data) => {
      const {
        allEventsJson: { edges },
      } = data

      const nodes = map(edges, 'node')
      const ordered = orderBy(nodes, ['publishedTimestamp', 'asc'])
      map(ordered, ({ publishedTimestamp: t }, index) => {
        ordered[index].year = moment(t * 1000).format('YYYY')
      })
      const years = map(ordered, 'year')
      const sortedUniqueYears = reverse(sortedUniq(years, 'year'))
      const {
        pageContext: {
          intl: { originalPath },
        },
      } = rest

      return (
        <StandardPageWrapper
          className={classNames(className, 'event-page', 'explorer')}
          {...rest}
        >
          <Row
            gutter={[
              { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
              { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
            ]}
          >
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              {children}
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <aside
                style={{
                  borderTop: '4px solid var(--orange1)',
                  marginTop: '1.1rem',
                  padding: '1.1rem',
                  background: 'var(--gray2)',
                }}
              >
                <h2 style={{ marginBottom: '0.55rem' }}>Other events...</h2>
                <p>
                  Our upcoming and past events held in the community including
                  presentations of our project, launch events as well as the
                  Unfold Your Skills workshops.
                </p>
                {map(sortedUniqueYears, (thisYear) => (
                  <Fragment>
                    <h3 style={{ marginTop: 'unset' }}>{thisYear}</h3>
                    <div className="events">
                      {map(ordered, ({ routeSlug, year }) => (
                        <Fragment>
                          {thisYear === year && (
                            <Link
                              className={classNames({
                                active: originalPath === routeSlug,
                              })}
                              to={routeSlug}
                            />
                          )}
                        </Fragment>
                      ))}
                    </div>
                  </Fragment>
                ))}
              </aside>
            </Col>
          </Row>
        </StandardPageWrapper>
      )
    }}
  />
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default PageWrapper
