// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

import map from 'lodash/map'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Breadcrumbs from '../breadcrumbs'
import '../breadcrumbs/style.less'

import PrevNext from '../prev-next'
import '../prev-next/style.less'

import EventPageWrapper from '../event-page-wrapper'
import '../event-page-wrapper/style.less'

import GridGallery from '../grid-gallery'
import '../grid-gallery/style.less'

import Video from '../video'
import '../video/style.less'

import Spotify from '../spotify'
import '../spotify/style.less'

import './style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const pageQuery = graphql`
  query EventPageTemplateQuery($routeSlug: String) {
    allEventsJson(filter: { routeSlug: { eq: $routeSlug } }) {
      nodes {
        title
        routeSlug
        publishedTimestamp
        shortAbstract
        contentBlocks {
          type
          content
          resources {
            cover {
              childImageSharp {
                gatsbyImageData(
                  width: 600
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
class Page extends React.PureComponent {
  /** [constructor description] */
  constructor() {
    super()
  }

  /** [render description] */
  render() {
    const {
      data: {
        allEventsJson: {
          nodes: [
            {
              shortAbstract,
              title,
              routeSlug,
              publishedTimestamp,
              contentBlocks,
            },
          ],
        },
      },
      pageContext: { next, prev, humanDate },
    } = this.props

    const pageSchema = {
      title,
      slug: routeSlug.substring(1),
      abstract: title,
      breadcrumbs: [
        { title: 'Homepage', slug: '' },
        { title: 'Community Events', slug: '/community-events' },
        { title, slug: routeSlug.substring(1) },
      ],
    }

    const now = Math.floor(new Date().getTime() / 1000)

    // <p className="hero">
    //   {publishedTimestamp >= now ? 'Scheduled for' : 'Published on'}
    //   &nbsp;
    //   {humanDate}
    // </p>

    return (
      <EventPageWrapper pageSchema={pageSchema} {...this.props}>
        <Breadcrumbs
          breadcrumbs={pageSchema.breadcrumbs}
          prev={prev}
          next={next}
        />
        <h1>{title}</h1>
        {map(contentBlocks, (block) => {
          const { type, content, resources } = block
          let returnThis = <Fragment />

          if (type === 'text') {
            returnThis = <p>{content}</p>
          }

          if (type === 'strong') {
            returnThis = <h2>{content}</h2>
          }

          if (type === 'gallery') {
            const images = []
            map(resources, ({ cover }) => {
              images.push({
                data: cover,
                height: cover.childImageSharp.gatsbyImageData.height,
                width: cover.childImageSharp.gatsbyImageData.width,
              })
            })

            returnThis = <GridGallery images={images} />
          }

          if (type === 'video') {
            returnThis = <Video url={content} />
          }

          if (type === 'spotify') {
            returnThis = <Spotify uri={content} />
          }

          return returnThis
        })}
      </EventPageWrapper>
    )
  }
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    headerState: state.headerState,
  })
  // dispatch => ({
  //   updateHeaderState(payload) {
  //     dispatch(updateHeaderState(payload))
  //   },
  // })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedPage = compose(
  withState // Add state
)(Page)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedPage
